
import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "./BaseForm";
import CountryFlag from "vue-country-flag";

interface BasicInfo {
	country: string;
	countryConfirmed?: boolean;
	instrument: "email" | "phone";
	type: "none" | "individual" | "brand";
	firstName: string;
	lastName: string;
	gender: string;
	brandName: string;
}

@Component({
	components: {
		CountryFlag,
	},
})
export default class ProfileSetupForm extends BaseForm {
	public labels: Record<string, string> = {
		country: "Country/Region",
		type: "Account Type",
		firstName: "First Name",
		lastName: "Last Name",
		gender: "Gender",
		brandName: "Brand Name",
	};

	@Prop({ required: true, default: [] })
	public countries!: string[];

	@Prop({ required: true, default: {} })
	public info!: Partial<BasicInfo>;

	public model: Record<string, any> = {
		country: this.info.country ?? "",
		type: this.info.type ?? null,
		firstName: this.info.firstName ?? null,
		lastName: this.info.lastName ?? null,
		gender: this.info.gender ?? "",
		brandName: this.info.brandName ?? null,
	};

	public test = "NG";

	public async send() {
		const response = await this.$api.post(this.action, this.model);
		if (!response.ok) {
			return this.handleError(response);
		}
		this.$alert.clear();
		this.$alert.addAlert({
			type: "success",
			message: response.message,
		});
		this.$emit("done", response.body);
	}
}
